<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ group.title || 'New Group' }}</h1>
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab exact-path :to="'/groups/' + group.id">Details</v-tab>
      <v-tab exact-path :to="'/groups/' + group.id + '/subgroups'" v-if="group.id > 0">Sub Groups</v-tab>
      <v-tab exact-path :to="'/groups/' + group.id + '/flights'" v-if="group.id > 0 && can('view flights')"
        >Flights</v-tab
      >
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="tabs" transition="false">
          <v-card flat>
            <v-card-text>
              <router-view :group_id="group.id" />
            </v-card-text>
          </v-card>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store/index';
import { mapGetters } from 'vuex';
import { can } from '@/utils/permissions';

export default {
  name: 'GroupView',
  beforeRouteEnter(to, from, next) {
    store.dispatch('group/get', to.params.id).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters('group', ['group'])
  },
  methods: {
    can: can
  },
  data: () => ({
    tabs: null,
    next: null,
    reportIds: [9, 10, 11, 12],
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Groups',
        exact: true,
        to: '/groups'
      },
      {
        text: 'Group',
        disabled: true,
        exact: true,
        to: '/groups/:id'
      }
    ]
  })
};
</script>
